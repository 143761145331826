/* eslint-disable no-unused-vars */
import four from "../assets/svg/projects/four.svg";

//
export const repositoriesData = [
  {
    id: 12,
    projectName: "File sharing in Golang",
    projectDesc:
      "Developing RESTful API with Go and Gin with Clean Architecture",
    tags: ["go", "gin", "gorm"],
    code: "https://github.com/Uuttssaavv/go-file-sharing/",
    demo: "https://github.com/Uuttssaavv/go-file-sharing/",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/0*7-jpOy-Lsu-c5CTj.png",
    underDevelopment: false,
  },

  {
    id: 13,
    projectName: "Flutter Clean Architecture with Riverpod",
    projectDesc:
      "Clean architecture provides a clear separation of concerns, encourages interfaces and abstractions, and facilitates changes in the dependencies without affecting the application’s core logic.",
    tags: ["Flutter", "riverpod", "dart"],
    code: "https://github.com/Uuttssaavv/flutter-clean-architecture-riverpod",
    demo: "https://github.com/Uuttssaavv/flutter-clean-architecture-riverpod",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*h4ahfMrkEhwmx5_Y6Y7zOA.png",
    underDevelopment: false,
  },
  {
    id: 14,
    projectName: "Clean Architecture in Flutter",
    projectDesc:
      "Clean architecture in flutter using flutter_bloc as a reactive programming.",
    tags: ["Flutter", "dart", "bloc"],
    code: "https://github.com/Uuttssaavv/flutter_clean_arch",
    demo: "https://github.com/Uuttssaavv/flutter_clean_arch",
    image: "https://miro.medium.com/max/1400/0*f0AzIlpcl3CGF5M2.png",
    underDevelopment: false,
  },
  {
    id: 1,
    projectName: "Flutter Animation Series",
    projectDesc:
      "A curated list of awesome animations, made purely in flutter and dart.",
    tags: ["flutter", "dart"],
    code: "https://github.com/Uuttssaavv/flutter_animation_series",
    demo: "https://github.com/Uuttssaavv/flutter_animation_series",
    image: four,
    underDevelopment: false,
  },
];
