/* eslint-disable */
import alok from "../assets/testimonials/aalok_niroula.jpg";
import abishek from "../assets/testimonials/abishek_doshi.jpeg";
import damodar from "../assets/testimonials/damodar_bhandari.jpg";
import fayez from "../assets/testimonials/fayez.jpeg";
import manish from "../assets/testimonials/manish_baral.jpg";
import niraj from "../assets/testimonials/niraj_kharel.jpg";
import sarbagya from "../assets/testimonials/sarbagya_dhaubanjar.jpg";
import umesh from "../assets/testimonials/umesh_basnet.jpg";

export const testimonialsData = [
  {
    id: 0,
    name: "Fayez Nicolas Chaar",
    title: "Chief Executive Officer",
    institution: "OurEasyGame LLC",
    text: " He is a Hero, without him we would have never fixed our problems! Came for the rescue and showed us a very professional work. Would definitely recommend him before any other mobile application developer!",
    image: fayez,
  },
  {
    id: 1,
    name: "Damodar Bhandari",
    title: "Campus Chief",
    institution: "Mahendra Morang Campus",
    text: "I would like to appreciate your effort on developing user friendly and handy product and the result was much better than what I had expected.",
    image: damodar,
  },

  {
    id: 7,
    name: "Sarbagya Dhaubanjar",
    title: "Senior Software Engineer",
    institution: "Khalti",
    text: "I've guided him from the begining of his flutter journey and he has the best learning attitude and one of the most hard working person out there.",
    image: sarbagya,
  },

  // {
  //   id: 2,
  //   name: "Adarsh Bajgain",
  //   title: "Chief Information Officer",
  //   institution: "Aayata Incorporations",
  //   text: "Utsav is good ",
  //   image:
  //     "https://scontent.fbir2-1.fna.fbcdn.net/v/t1.6435-1/p200x200/122713783_3515529045176931_8855398376324709511_n.jpg?_nc_cat=100&ccb=1-5&_nc_sid=7206a8&_nc_ohc=wGSA_YT9iwYAX8C8LMy&_nc_ht=scontent.fbir2-1.fna&oh=6a7d52f2b9464cbbd4d7766eeea6d2a4&oe=61584B7F",
  // },
  {
    id: 4,
    name: "Aalok Niroula",
    title: "Chief Executive Officer",
    institution: "An4Soft Pvt. Ltd.",
    text: "He is reliable, professional and easy to work with. I cannot recommend him highly enough and we look forward to continuing our working relationship together.",
    image: alok,
  },
  {
    id: 5,
    name: "Manish Baral",
    title: "Chief Executive Officer",
    institution: "i-Nepal Pvt. Ltd.",
    text: "He is reliable, professional and easy to work with. I cannot recommend him highly enough and we look forward to continuing our working relationship together.",
    text: "Utsav is awesome, delivers exactly what you want and is a great guy as well, thanks for all your hard work during your journey with i-Nepal.",
    image: manish,
  },
  {
    id: 6,
    name: "Niraj Kharel",
    title: "Cyber Security Analyst",
    institution: "Cryptogen Nepal",
    text: "I honestly wish there were more people like him in the development side of things. Because I've found that Utsav invests a lot of time and resources which polish his work.",
    image: niraj,
  },
  // {
  //   id: 6,
  //   name: "Ujjuwal Mainaly",
  //   title: "Chief Executive Officer",
  //   institution: "Mainaly IT and Solutions",
  //   text: "He is reliable, professional and easy to work with. I cannot recommend him highly enough and we look forward to continuing our working relationship together.",
  //   text: lipsum,
  //   image:
  //     "https://scontent.fbir2-1.fna.fbcdn.net/v/t1.6435-1/p200x200/196093800_3676016025838499_8191311489278127761_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=7206a8&_nc_ohc=p2kguHtcjNkAX_U91NN&_nc_ht=scontent.fbir2-1.fna&oh=037c19ae27d63caf1755b3c84d28dc84&oe=6158B7E6",
  // },
  {
    id: 7,
    name: "Umesh Basnet",
    title: "Tech Logistic Lead",
    institution: "Young Innovations Pvt. Ltd.",
    text: "I sincerely hope my testimonial does his work justice, as I really can't find the words to  recommend him highly enough.",
    image: umesh,
  },
  {
    id: 0,
    name: "Abhishek Doshi",
    title: "Flutter/Dart",
    institution: "Google Developer Expert(GDE)",
    text: "He is not someone who just follows the orders and give the most viable work, but he is someone who challenges and get the best work out of the task assigned. Very pleasant to work with Utsav and looking forward to more collaborations :)",
    image: abishek,
  },
];
