import html from "../assets/svg/skills/html.svg";
import css from "../assets/svg/skills/css.svg";
import react from "../assets/svg/skills/react.svg";
import dart from "../assets/svg/skills/dart.svg";
import django from "../assets/svg/skills/django.svg";
import firebase from "../assets/svg/skills/firebase.svg";
import flutter from "../assets/svg/skills/flutter.svg";
import git from "../assets/svg/skills/git.svg";
import golang from "../assets/svg/skills/gopher.svg";
import nodejs from "../assets/svg/skills/nodejs.svg";

export const skillsImage = (skill) => {
  const skillID = skill.toLowerCase();
  switch (skillID) {
    case "html":
      return html;
    case "css":
      return css;
    case "react":
      return react;
    case "django":
      return django;
    case "firebase":
      return firebase;
    case "git":
      return git;
    case "dart":
      return dart;
    case "flutter":
      return flutter;
    case "go":
      return golang;
    case "nodejs":
      return nodejs;
    default:
      break;
  }
};
