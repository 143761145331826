export const skillsData = [
  //   "HTML",
  //   "CSS",
  "React",
  "Nodejs",
  "Flutter",
  "Go",
  "Firebase",
  "Dart",
  "Git",
];
