import image from "../assets/svg/about/about.jpg";

export const aboutData = {
  title: "Who am I?",
  description1: "Hi👋 I am Utsav.",
  description2:
    "I am a Senior Software Engineer with 7+ years of experience in **Flutter**, **Node.js**, and **Golang**. I have successfully led teams and managed large-scale projects, delivering robust mobile applications, server-side systems, and APIs. My focus is on quality, efficiency, and adherence to industry best practices.",
  description3: `Key strengths include:
  ● **5+ years** of expertise in cross-platform development and testing using **Flutter** and **Dart**.
  ● **2+ years** in native libraries for both **iOS** and **Android**.
  ● **3+ years** building scalable back-end systems with **Node js/ts**.
  ● **Test Driven Development**(TDD) and **Behavior Driven Development**(BDD)
  ● Experienced in writing unit tests and widget testing.
  ● Experienced in integration testing using **Patrol**, **Browserstack**, **Appium** and setting **CI/CD** pipeline with **Github Actions**.
  ● Perform **Functional**, **Regression** and **Usability Testing** of Mobile application on both Android and iOS
  ● Skilled in building scalable back-end systems with **Go language**.
  ● Proven leadership in managing and delivering complex software projects.
  ● Strong collaboration skills in dynamic, team-oriented environments.

    `,
  description4:
    "I am driven by a commitment to excellence and a passion for creating innovative software solutions that meet the highest standards.",
  image: image,
};
