import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useContext, useState } from "react";
import Fade from "react-reveal/Fade";
import { ThemeContext } from "../../contexts/ThemeContext";

import expImgWhite from "../../assets/svg/experience/expImgWhite.svg";

import "./Experience.css";

function ExperienceCard({
  id,
  company,
  jobtitle,
  startYear,
  endYear,
  description,
}) {
  const { theme } = useContext(ThemeContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const classes = useStyles();

  return (
    <Fade bottom>
      <div
        key={id}
        className={`experience-card ${classes.experienceCard}`}
        onClick={handleModalOpen}
      >
        <div className="expcard-img" style={{ backgroundColor: theme.primary }}>
          <img src={expImgWhite} alt="" />
        </div>
        <div className="experience-details">
          <h6 style={{ color: theme.primary }}>
            {startYear} - {endYear}
          </h6>
          <h4 style={{ color: theme.tertiary }}>{jobtitle}</h4>
          <h6 style={{ color: theme.tertiary80 }}>{company}</h6>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        className={classes.modal}
      >
        <div className={classes.modalContent}>
          <div
            className={classes.closeIconContainer}
            style={{ backgroundColor: theme.primary }}
            onClick={handleModalClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </div>
          <div className={classes.scrollableListContainer}>
            <ul className={classes.modalDescription}>
              {description.map((work) => (
                <li key={work} style={{ listStyleType: "none" }}>
                  <span
                    style={{
                      fontWeight: work.includes("●") ? "normal" : "bold",
                      textDecoration: work.includes("●") ? "none" : "underline",
                      padding: "4px 0px",
                      display: "block",
                    }}
                  >
                    {work}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </Fade>
  );
}

export default ExperienceCard;

const useStyles = makeStyles((t) => ({
  experienceCard: {
    backgroundColor: "#ffff",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#cccc",
    },
  },
  scrollableListContainer: {
    maxHeight: "60vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#cccccc",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#003153",
      borderRadius: "3px",
    },
  },
  closeIconContainer: {
    position: "absolute",
    opacity: "1",
    top: "30px",
    right: "20px",
    cursor: "pointer",
    borderRadius: "50%",
    padding: "2%",
    height: "40px",
    width: "40px",
    [t.breakpoints.up("sm")]: {
      opacity: "0",
    },
  },

  closeIcon: {
    color: "white",
    cursor: "pointer",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "white",
    padding: "2%",
    borderRadius: "4px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
    width: "95%",
    [t.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
  },

  modalDescription: {
    padding: 0,
    margin: 0,
    listStyleType: "none",
  },
}));
