import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { BackToTop } from "./components";
import { BlogPage, Main, ProjectPage, RepositoriesPage } from "./pages";
import ScrollToTop from "./utils/ScrollToTop";

import "./App.css";

function App() {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/blog" exact component={BlogPage} />
          <Route path="/projects" exact component={ProjectPage} />
          <Route path="/repositories" exact component={RepositoriesPage} />
          <Route path="/qr" exact component={QRRedirect} />
          <Redirect to="/" />
        </Switch>
        <BackToTop />
      </Router>
    </div>
  );
}

const QRRedirect = () => {
  useEffect(() => {
    window.location.href = "https://enabimnio.com/";
  }, []);
  return null;
};

export default App;
