/* eslint-disable */
import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { FaAppStoreIos } from "react-icons/fa";
import { MdAndroid, MdApps } from "react-icons/md";

export const servicesData = [
  {
    id: 1,
    title: "Android App Development",
    icon: <MdAndroid />,
  },
  {
    id: 2,
    title: "iOS App Development",
    icon: <FaAppStoreIos />,
  },
  {
    id: 3,
    title: "Designing",
    icon: <BsClipboardData />,
  },
  {
    id: 4,
    title: "Web Design",
    icon: <BsCodeSlash />,
  },
  {
    id: 5,
    title: "Backend Development",
    icon: <MdApps />,
  },
];
