import resume from "../assets/pdf/utsav_g_updated_resume.pdf";
import image from "../assets/svg/about/image.png";

export const headerData = {
  name: "Utsav Ghimire",
  desciption:
    // "Hello👋 I am Full Stack Mobile Application Developer working on freelance projects.",
    // "Hello👋 Welcoming you to my portfolio site.",
    "Welcome to my portfolio website! I hope you enjoy exploring my work and getting to know more about me and my approach to software development.",
  image: image,
  resumePdf: resume,
};
