import { useEffect } from "react";
// for google analytics
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import {
  About,
  Blog,
  Contacts,
  Education,
  Experience,
  // Footer,
  Landing,
  Navbar,
  Projects,
  Repositories,
  Services,
  Skills,
  Testimonials,
} from "../../components";
import { headerData } from "../../data/headerData";

// initialize google analytics
ReactGA.initialize("G-L53QDQB35J");
function Main() {
  useEffect(() => {
    ReactGA.send("page-visit");
  });
  return (
    <div>
      <Helmet>
        <title>{headerData.name}</title>
      </Helmet>
      <Navbar />
      <Landing />
      <About />
      <Experience />
      <Skills />
      <Projects />
      <Repositories />

      <Blog />
      <Education />
      <Services />
      <Testimonials />
      <Contacts />
      {/* <Footer /> */}
    </div>
  );
}

export default withRouter(Main);
