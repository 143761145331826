export const blogData = [
  {
    id: 0,
    title: "Flutter: Why use map function instead of loops?",
    description:
      "In this article, we will explore through all the loops in dart and choose the best one for our usecase.",
    date: "July 31, 2022",
    image: "https://cdn-images-1.medium.com/max/1600/0*-VTkpjxS6hktPsAM",
    url: "https://theutsavg1.medium.com/flutter-why-use-map-function-instead-of-loops-8a22c2444ece",
  },
  {
    id: 1,
    title: "Write and test your self-made API using Django in Flutter App",
    description:
      "In this article, you will learn how to host your rest api made with Django Rest Framework and import and use in your flutter application.",
    date: "Aug 7, 2020",
    image: "https://miro.medium.com/max/1400/1*nSVke1bscf1XSN5GtFwZ4Q.png",
    url: "https://theutsavg.medium.com/flutter-and-django-on-localhost-9e28334281ca",
  },
  {
    id: 2,
    title: "Where and how to use dependency injection in Flutter.",
    description:
      "This article will help you to go through the basics of Dependecy Injection using flutter approach to using the external packages.",
    date: "July 25, 2022",
    image: "https://i.ytimg.com/vi/tW6UHD81SG0/maxresdefault.jpg",
    url: "https://theutsavg1.medium.com/dependency-injection-with-flutter-afd560c06750",
  },
  {
    id: 3,
    title: "Beautiful Light animation using flutter",
    description:
      "By the end of this article, you will learn about custom paint, radial text styling, render box and so on",
    date: "July 19, 2022",
    image: "https://miro.medium.com/proxy/1*gsIwEEMEdeANsjLy1gc0NA.gif",
    url: "https://theutsavg1.medium.com/creating-beautiful-bulb-animation-with-flutter-3acda2a2c9e7",
  },
  {
    id: 4,
    title: "Zero to Hero in Flutter Animations",
    description:
      "The article will help you to know about both implicit and explicit flutter animations.",
    date: "Jul 22, 2020",
    image: "https://miro.medium.com/max/1400/0*rRoJKjvUk5mlLwOx.jpg",
    url: "https://theutsavg1.medium.com/zero-to-hero-in-flutter-animations-3c259834e83b",
  },
  {
    id: 14,
    title: "Custom Render Objects in Flutter",
    description:
      "The article demonstrates to create custom render objects in Flutter",
    date: "July 3, 2024",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/0*GqH_HsiUElDr4SNi.png",
    url: "https://theutsavg1.medium.com/custom-render-objects-in-flutter-d723a814b3ad",
  },
  {
    id: 5,
    title: "What the f*ck is blockchain?",
    description:
      "Everything you need to know about blockchain is covered in this article.",
    date: "Feb 9, 2022",
    image: "https://miro.medium.com/max/508/1*GHXXUs-xsSZoDxr7N5fpNQ.png",
    url: "https://blog.cryptostars.is/blockchain-the-future-619b81fa75e3",
  },
  {
    id: 6,
    title: "Dart: Equatable",
    description:
      "Why do we need Equatable package even if there are equality operators in Dart?",
    date: "Aug 10, 2022",
    image: "https://miro.medium.com/max/1400/0*Zynk2TT_wgAlmJEF.png",
    url: "https://theutsavg1.medium.com/56ebd6aad9e2",
  },
  {
    id: 7,
    title: "Flutter: Socket client with Riverpod",
    description:
      "To demonstrate how to communicate with the socket server in Flutter using Riverpod as state management, I made this tic tac toe game.",
    date: "Aug 22, 2022",
    image: "https://miro.medium.com/max/1400/0*q4I3njHZwnRbqwGT.png",
    url: "https://theutsavg1.medium.com/flutter-socket-client-with-riverpod-8cbc30c6a7e4",
  },
  {
    id: 8,
    title: "Clean Architecture in Flutter",
    description:
      "Clean Architecture in Flutter based on Reso Coder's tutorial but implemented using feature-driven pattern.",
    date: "Aug 27, 2022",
    image: "https://miro.medium.com/max/1400/0*f0AzIlpcl3CGF5M2.png",
    url: "https://theutsavg1.medium.com/clean-architecture-in-flutter-8aadab851047",
  },
  {
    id: 9,
    title: "Flutter: Managing Sessions",
    description: "Best way to manage session in Flutter",
    date: "Oct 07, 2022",
    image: "https://miro.medium.com/max/1400/0*4WZOche1-iPUh5M3.png",
    url: "https://theutsavg1.medium.com/flutter-managing-sessions-13fe3f0850dc",
  },
  {
    id: 10,
    title: "Flutter: Intercept your http requests",
    description:
      "Making the most out of interceptors for request manipulations in Flutter",
    date: "Nov 11, 2022",
    image: "https://miro.medium.com/max/1400/1*u2SeMCzlv0-9AobuARrJYg.png",
    url: "https://medium.com/@theutsavg1/flutter-intercept-your-requests-8113ef39b4d9",
  },
  {
    id: 11,
    title: "Dart: Streams",
    description: "Understanding streams in Dart",
    date: "Dec 16, 2022",
    image: "https://miro.medium.com/max/1400/0*qfWvPb1v8_2bZerq.webp",
    url: "https://medium.com/@theutsavg1/dart-streams-5e5e17a2edae",
  },
  {
    id: 12,
    title: "Git commands",
    description: "10 git commands every developer should know",
    date: "Dec 26, 2022",
    image: "https://miro.medium.com/max/1400/0*DsJjBZTc19pSbPgR.webp",
    url: "https://theutsavg1.medium.com/git-commands-explained-1335deacf227",
  },
  {
    id: 12,
    title: "Flutter: Clean Architecture with Riverpod",
    description:
      "Clean architecture provides a clear separation of concerns, encourages interfaces and abstractions, and facilitates changes in the dependencies without affecting the application’s core logic.",
    date: "Mar 9, 2023",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*h4ahfMrkEhwmx5_Y6Y7zOA.png",
    url: "https://theutsavg1.medium.com/implementing-clean-architecture-with-riverpod-for-modular-flutter-apps-7d21acfa9db0",
  },
  {
    id: 13,
    title: "Clean coding practices",
    description:
      "Seven things to keep in mind for writing clean code and crafting better software.",
    date: "May 3, 2023",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*zrQKE7j-_GyGjySj3ZkHkQ.png",
    url: "https://theutsavg1.medium.com/clean-coding-practices-10c8b1745349",
  },
  {
    id: 14,
    title: "Flutter: Background tasks",
    description: "Handling the bulky tasks in the background",
    date: "May 8, 2023",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*XLBJhRd1xuH-VpsaiL3hqw.png",
    url: "https://theutsavg1.medium.com/flutter-background-tasks-dda02f98182",
  },
  {
    id: 14,
    title: "Developing RESTful API with Go and Gin with Clean Architecture",
    description:
      "This article will help beginners learn backend development using Go. It covers a wide range of beginner concepts and techniques in the backend development space.",
    date: "June 3, 2023",
    image:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/0*7-jpOy-Lsu-c5CTj.png",
    url: "https://medium.com/@theutsavg1/developing-restful-api-with-go-and-gin-with-clean-architecture-61c526d42876",
  },
];
