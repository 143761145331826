export const experienceData = [
  {
    id: 1,
    company: "Oot Social Health",
    jobtitle: "Senior Software Engineer",
    startYear: "October 2023",
    endYear: "Present",
    description: [
      "Key Responsibilities:",
      "● Leading the design and implementation of software solutions.",
      "● Collaborating with stakeholders to gather and analyze requirements.",
      "● Conducting code reviews and providing constructive feedback.",
      "● Architecting scalable and maintainable software systems.",
      "● Researching and evaluating new technologies and tools.",
      "● Troubleshooting complex technical issues and providing timely resolutions.",
      "● Keeping abreast of industry trends and developments in software engineering.",
      "● Identifying and addressing technical debt and code quality issues.",
      "● Taking ownership of project deliverables and meeting deadlines.",
    ],
  },
  {
    id: 2,
    company: "Qubited Corporations",
    jobtitle: "Software Engineer",
    startYear: "December 2021",
    endYear: "October 2023",
    description: [
      "React Native:",
      "● Proficient in Redux or similar state management.",
      "● Familiarity with Xcode and Android Studio.",
      "● Testing: unit, integration, end-to-end (enzyme, jest).",
      "● RESTful API integration, JSON handling.",
      "● GraphQL integration, optimization.",
      "● App deployment to App Store, Google Play.",
      "● Agile methodologies familiarity.",
      "Flutter:",
      "● Expert in Flutter, Dart language.",
      "● Bloc, Provider, Riverpod for state.",
      "● Clean Architecture, MVVM, Repository.",
      "● Troubleshooting and debugging skills.",
      "● Design principles, patterns, best practices.",
      "● Thorough testing: unit, widget, integration.",
      "● Integration testing: appium, browserstack, and patrol.",
      "● Cross-platform app development.",
      "● RESTful API, GraphQL integration.",
      "● Firebase, Crashlytics implementation.",
      "● Third-party package integration.",
      "Integrations:",
      "● Offline storage, isolates for performance.",
      "● Cloud message APIs, push notifications.",
      "● Scalable, reliable stack design.",
      "● App performance optimization.",
      "● Git, version control proficiency.",
      "● GitHub workflows for collaboration.",
    ],
  },
  {
    id: 3,
    company: "i-Nepal Pvt. Ltd.",
    jobtitle: "Associate Software Engineer",
    startYear: "March 2020",
    endYear: "July 2021",
    description: [
      "Flutter Specific:",
      "● Write scalable and maintainable code.",
      "● State management using Provider.",
      "● Integrating various plugins like Google Maps, ffmpeg, payment gateways, and video conference using Agora SDK.",
      "● Testing application on various platforms.",
      "● Git, version control proficiency.",
      "● GitHub workflows for collaboration.",
      "Django Specific:",
      "● Design backend systems for mid-level projects.",
      "● Developing APIs using Django REST Framework.",
      "● Serialization and ORM.",
    ],
  },
  {
    id: 4,
    company: "An4Soft Pvt. Ltd.",
    jobtitle: "Mobile Application Developer",
    startYear: "March 2019",
    endYear: "March 2020",
    description: [
      "● Responsive UI design.",
      "● Connecting Flutter app to Firebase.",
      "● Connecting Flutter app with Backend using HTTP.",
      "● Using device sensors like Camera, fingerprint, etc.",
      "● Git, version control proficiency.",
      "● GitHub workflows for collaboration.",
    ],
  },
];
