import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import { HiArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../contexts/ThemeContext";
import { projectsData } from "../../data/projectsData";
import { repositoriesData } from "../../data/repositoriesData";
import "./Repositories.css";
import SingleRepository from "./SingleProject/SingleProject";

function Repositories() {
  const { theme } = useContext(ThemeContext);

  const useStyles = makeStyles(() => ({
    viewAllBtn: {
      color: theme.primary,
      backgroundColor: "#dddd",
      transition: "color 0.2s",
      "&:hover": {
        color: "#ffff",
        backgroundColor: theme.primary,
      },
    },
    viewArr: {
      color: theme.primary,
      backgroundColor: "#bbbb",
      width: "40px",
      height: "40px",
      padding: "0.5rem",
      fontSize: "1.05rem",
      borderRadius: "50%",
      cursor: "pointer",
      transition: "background-color 0.2s",
      "&:hover": {
        color: "#ffff",
        backgroundColor: "#aaaa",
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      {projectsData.length > 0 && (
        <div
          className="projects"
          id="projects"
          style={{ backgroundColor: " theme.secondary" }}
        >
          <div className="projects--header">
            <h1 style={{ color: theme.primary }}>Repositories</h1>
          </div>
          <div className="projects--body">
            <div className="projects--bodyContainer">
              {repositoriesData.slice(0, 3).map((repo) => (
                <SingleRepository
                  theme={theme}
                  key={repo.id}
                  id={repo.id}
                  name={repo.projectName}
                  desc={repo.projectDesc}
                  tags={repo.tags}
                  code={repo.code}
                  demo={repo.demo}
                  image={repo.image}
                  underDevelopment={repo.underDevelopment}
                />
              ))}
            </div>

            {projectsData.length > 3 && (
              <div className="projects--viewAll">
                <Link to="/repositories">
                  <button className={classes.viewAllBtn}>
                    View All
                    <HiArrowRight className={classes.viewArr} />
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Repositories;
