/* eslint-disable eqeqeq */
import { makeStyles } from "@material-ui/core/styles";
import { FaAppStore, FaGlobe } from "react-icons/fa";
import Fade from "react-reveal/Fade";

import placeholder from "../../../assets/png/placeholder.png";
import "./SingleProject.css";

function SingleProject({
  id,
  name,
  desc,
  tags,
  code,
  demo,
  image,
  theme,
  underDevelopment,
}) {
  const useStyles = makeStyles((t) => ({
    iconBtn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 40,
      height: 40,
      borderRadius: 50,
      border: `2px solid #ffff`,
      color: "#ffff",
      transition: "all 0.2s",
      "&:hover": {
        backgroundColor: theme.secondary,
        color: theme.primary,
        transform: "scale(1.1)",
        border: `2px solid ${theme.secondary}`,
      },
    },
    icon: {
      fontSize: "1.1rem",
      transition: "all 0.2s",
      "&:hover": {},
    },
  }));

  const classes = useStyles();

  return (
    <Fade bottom>
      <div
        key={id}
        className="singleProject"
        style={{ backgroundColor: "#041E42" }}
      >
        <div className="projectContent">
          <h2 style={{ color: "#fff" }}>{name}</h2>
          <img
            src={image ? image : placeholder}
            alt={name}
            style={{
              filter: name != "Enabimnio" ? "" : "invert(1) brightness(1000%)",
              objectFit: "contain",
              maxWidth: "100%",
              maxHeight: "60%",
              width: "100%",
              height: "auto",
            }}
          />
          <div className="project--showcaseBtn">
            <a
              href={demo}
              target="_blank"
              rel="noreferrer"
              style={{ display: underDevelopment ? "none" : "absolute" }}
              className={classes.iconBtn}
            >
              <FaAppStore className={classes.icon} styles={{ width: "100%" }} />
            </a>
            <a
              href={code}
              target="_blank"
              rel="noreferrer"
              className={classes.iconBtn}
            >
              <FaGlobe className={classes.icon} />
            </a>
          </div>
        </div>
        <p
          className="project--desc"
          style={{ background: "#ffff", color: theme.tertiary }}
        >
          {desc}
        </p>
        <div
          className="project--lang"
          style={{ background: theme.secondary, color: theme.tertiary80 }}
        >
          {tags.map((tag, id) => (
            <span key={id}>{tag}</span>
          ))}
        </div>
      </div>
    </Fade>
  );
}

export default SingleProject;
