import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { aboutData } from "../../data/aboutData";
import "./About.css";

function About() {
  const { theme } = useContext(ThemeContext);

  const renderText = (text) => {
    // Split the text by newlines to preserve line breaks
    const paragraphs = text.split("\n");

    return paragraphs.map((paragraph, index) => {
      // Replace **bold** with <strong> elements
      const formattedParagraph = paragraph.split("**").map((part, i) => {
        return i % 2 !== 0 ? (
          <strong key={i} style={{ fontWeight: 600 }}>
            {part}
          </strong>
        ) : (
          part
        );
      });

      return (
        <span key={index}>
          {formattedParagraph}
          {index < paragraphs.length - 1 && <br />}{" "}
          {/* Insert <br /> after each paragraph */}
        </span>
      );
    });
  };
  return (
    <div
      className="about"
      id="about"
      style={{ backgroundColor: theme.secondary }}
    >
      <div className="line-styling">
        <div
          className="style-circle"
          style={{ backgroundColor: theme.primary }}
        ></div>
        <div
          className="style-circle"
          style={{ backgroundColor: theme.primary }}
        ></div>
        <div
          className="style-line"
          style={{ backgroundColor: theme.primary }}
        ></div>
      </div>
      <div className="about-body">
        <div className="about-description">
          <h2 style={{ color: theme.primary }}>{aboutData.title}</h2>
          <p style={{ color: theme.tertiary80 }}>
            {renderText(aboutData.description1)}
            <br />
            <br />
            {renderText(aboutData.description2)}
            <br />
            <br />
          </p>
          <p style={{ color: theme.tertiary80, whiteSpace: "pre-line" }}>
            {renderText(aboutData.description3)}
            <br />
            {renderText(aboutData.description4)}
          </p>
        </div>
        <div className="about-img">
          <img src={aboutData.image} alt="about_image" />
        </div>
      </div>
    </div>
  );
}

export default About;
