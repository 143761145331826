export const educationData = [
  {
    id: 1,
    institution: "Little Angels' Boarding School",
    course: "School Leaving Certificate",
    startYear: "2003",
    endYear: "2016",
  },
  {
    id: 2,
    institution: "Kankai Foundation",
    course: "Physical Science",
    startYear: "July 2016 ",
    endYear: " June 2018",
  },
  {
    id: 3,
    institution: "Tribhuwan University",
    course: "Bachelor of CS and IT",
    startYear: "August 2018  ",
    endYear: "  Present",
  },
];
