import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useContext, useState } from "react";
import { FaBriefcase, FaGithub, FaNewspaper, FaUser } from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";
import { IoMenuSharp } from "react-icons/io5";
import { MdPhone } from "react-icons/md";
import Fade from "react-reveal/Fade";
import { NavHashLink as NavLink } from "react-router-hash-link";

import { ThemeContext } from "../../contexts/ThemeContext";
import "./Navbar.css";

function Navbar() {
  const { theme, setHandleDrawer } = useContext(ThemeContext);

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
    setHandleDrawer();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setHandleDrawer();
  };

  const useStyles = makeStyles((t) => ({
    navMenu: {
      fontSize: "2.5rem",
      color: theme.tertiary,
      cursor: "pointer",
      transform: "translateY(-10px)",
      transition: "color 0.3s",
      overflow: "scroll",
      "&:hover": {
        color: theme.primary,
      },
      [t.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
      [t.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
    },
    MuiDrawer: {
      padding: "0em 1.8em",
      width: "14em",
      fontFamily: " var(--primaryFont)",
      fontStyle: " normal",
      fontWeight: " normal",
      fontSize: " 24px",
      background: theme.secondary,
      overflow: "hidden",
      borderTopRightRadius: "40px",
      borderBottomRightRadius: "40px",
      [t.breakpoints.down("sm")]: {
        width: "12em",
      },
    },
    closebtnIcon: {
      fontSize: "2rem",
      fontWeight: "bold",
      cursor: "pointer",
      color: theme.primary,
      position: "absolute",
      right: 40,
      top: 40,
      transition: "color 0.2s",
      "&:hover": {
        color: theme.tertiary,
      },
      [t.breakpoints.down("sm")]: {
        right: 20,
        top: 20,
      },
    },
    drawerItem: {
      margin: "2rem auto",
      borderRadius: "78.8418px",
      background: theme.secondary,
      color: theme.primary,
      width: "85%",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      padding: "20 0px",
      boxSizing: "border-box",
      border: "2px solid",
      borderColor: theme.primary,
      transition: "background-color 0.2s, color 0.2s",
      "&:hover": {
        background: theme.primary,
        color: theme.secondary,
      },
      [t.breakpoints.down("sm")]: {
        width: "100%",
        padding: "0 0px",
        height: "55px",
      },
    },
    drawerLinks: {
      fontFamily: "var(--primaryFont)",
      width: "50%",
      fontSize: "1.3rem",
      fontWeight: 600,
      [t.breakpoints.down("sm")]: {
        fontSize: "1.125rem",
      },
    },
    drawerIcon: {
      color: theme.primary,
      fontSize: "1.6rem",
      [t.breakpoints.down("sm")]: {
        fontSize: "1.385rem",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="navbar">
      <div className="navbar--container">
        <h3 style={{ color: theme.secondary }}>theutsavg.</h3>

        <IoMenuSharp className={classes.navMenu} onClick={handleDrawerOpen} />
      </div>
      <Drawer
        variant="temporary"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleDrawerClose();
          } else if (reason !== "escapeKeyDown") {
            handleDrawerClose();
          }
        }}
        anchor="left"
        open={open}
        classes={{ paper: classes.MuiDrawer }}
        className="drawer"
        disableScrollLock={true}
      >
        <div className="div-closebtn">
          <CloseIcon
            onClick={handleDrawerClose}
            className={classes.closebtnIcon}
          />
        </div>
        <br />

        <div onClick={handleDrawerClose}>
          <div className="navLink--container">
            {/* experience */}
            <Fade left>
              <NavLink
                to="/#experience"
                smooth={true}
                spy="true"
                duration={2000}
              >
                <div className={classes.drawerItem}>
                  <FaBriefcase
                    className={[
                      classes.drawerIcon,
                      { color: classes.drawerItem.color },
                    ]}
                  />
                  <span className={classes.drawerLinks}>Experience</span>
                </div>
              </NavLink>
            </Fade>

            {/* about */}
            <Fade left>
              <NavLink to="/#about" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <FaUser
                    className={[
                      classes.drawerIcon,
                      { color: classes.drawerItem.color },
                    ]}
                  />
                  <span className={classes.drawerLinks}>About</span>
                </div>
              </NavLink>
            </Fade>
            {/* Education */}
            <Fade left>
              <NavLink
                to="/#education"
                smooth={true}
                spy="true"
                duration={2000}
              >
                <div className={classes.drawerItem}>
                  <HiDocumentText
                    className={[
                      classes.drawerIcon,
                      { color: classes.drawerItem.color },
                    ]}
                  />
                  <span className={classes.drawerLinks}>Education</span>
                </div>
              </NavLink>
            </Fade>
            {/* blog */}
            <Fade left>
              <NavLink to="/blog" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <FaNewspaper
                    className={[
                      classes.drawerIcon,
                      { color: classes.drawerItem.color },
                    ]}
                  />
                  <span className={classes.drawerLinks}>Blogs</span>
                </div>
              </NavLink>
            </Fade>
            {/* blog */}
            <Fade left>
              <NavLink
                to="/repositories"
                smooth={true}
                spy="true"
                duration={2000}
              >
                <div className={classes.drawerItem}>
                  <FaGithub
                    className={[
                      classes.drawerIcon,
                      { color: classes.drawerItem.color },
                    ]}
                  />
                  <span className={classes.drawerLinks}>Repos</span>
                </div>
              </NavLink>
            </Fade>
            {/* contacts */}
            <Fade left>
              <NavLink to="/#contacts" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <MdPhone
                    className={[
                      classes.drawerIcon,
                      { color: classes.drawerItem.color },
                    ]}
                  />
                  <span className={classes.drawerLinks}>Contact</span>
                </div>
              </NavLink>
            </Fade>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Navbar;
